
.AppPage {
    font-size: calc(10px + 1vmin);
    color: white;
    font-family: monospace;
}

html {
    scroll-behavior: smooth;
}

#root {
    background-color: #566E57;
}


